<template>
	<div class="">
		<div class="ObligeeWrap serviceMain" v-if="tabIdx=='tab1'">
			<div class="searchBar">
				<div class="tittle"> 著作权人管理 </div>
				<div class="rightForm">
					<div @click="clickTab('tab2')">
						<el-button type="primary">新增个人著作权人</el-button>
					</div>
					<div @click="clickTab('tab3')">
						<el-button type="primary">新增企业著作权人</el-button>
					</div>
				</div>
			</div>

			<el-table :data="tableData" :cell-class-name="tabelCellClass" style="width: 100%">
				<el-table-column label="个人/企业" width="120">
					<template slot-scope="scope">
						<span v-if="scope.row.obligeeType == '1'">个人</span>
						<span v-else-if="scope.row.obligeeType == '2'">企业</span>
					</template>
				</el-table-column>
				<el-table-column label="著作权人">
					<template slot-scope="scope">
						<span v-if="scope.row.obligeeType == '1'">{{scope.row.userName}}</span>
						<span v-else-if="scope.row.obligeeType == '2'">{{scope.row.companyName}}</span>
					</template>
				</el-table-column>
		
				<!-- <el-table-column prop="userName" label="著作权人" width="180">
				</el-table-column> -->
				<el-table-column prop="address" label="地址" max-width="200">
					<template slot-scope="scope">
						<span>{{scope.row.province}}-{{scope.row.city}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="expireDate" :formatter="dateFormatter" label="证件有效期" width="140">
				</el-table-column>
				
				<el-table-column prop="signature" label="电子签名/印章"  width="160" min-width="120">
					<template slot-scope="scope">
						<el-image class="img"
							v-if="scope.row.supportingMaterial"
							:src="scope.row.supportingMaterial.signature"
							:preview-src-list="[scope.row.supportingMaterial.signature]"
						></el-image>
						<el-button type="text" size="small" v-if="!scope.row.supportingMaterial" @click="changeSignature(scope.row.uniqueCode)">添加</el-button>
						<el-button type="text" size="small" v-else @click="changeSignature(scope.row.uniqueCode)">修改</el-button>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="100">
					<template>
						<el-button type="text" size="small" disabled="disabled">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="pagination.total" :current-page.sync="pagination.page"
			 :page-size.sync="pagination.pageSize" @size-change="sizeChange" @current-change="currentChange">
			</el-pagination>
			<real-name-dialog
			v-if="dialogVisible"
					 @close="onDialogClose"
					 ></real-name-dialog>
		</div>
		<div v-if="tabIdx=='tab2'">
			<add-personal @set-tab-idx="setTabIdx" ></add-personal>
		</div>
		<div v-if="tabIdx=='tab3'">
			<add-company @set-tab-idx="setTabIdx"></add-company>
		</div>

		<!-- 修改签名/印章 弹窗 -->
		<el-dialog
			:visible.sync="showChangeSignature"
			width="460px"
			center
			title="修改电子签名/印章"
			@closed="onCloseChangeSignature"
			>
			<div class="changeSignatureBox">
				<div class="uploadBusinessLicense" >
					<el-upload class="Uploader"  :action="uploadUrl"  :headers="uploadHeaders" :show-file-list="false" :on-success="uploadSuccess"
						:before-upload="uploadBefore">
						<img v-if="form.supportingMaterial.signature" :src="form.supportingMaterial.signature" class="avatar">
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
					<div class="clear"></div>
				</div>
				<div class="blockReminder">
					<div class="description" slot="description">
						请上传长宽为120px的等比例PNG图片
					</div>
				</div>
				<el-button type="primary" @click="changeSignatureBtn">提交</el-button>
			</div>
		</el-dialog>

	</div>
	
</template>

<script>
	import AddCompany from './components/AddCompany'
	import AddPersonal from './components/AddPersonal'
	import RealNameDialog from '@/components/RealNameDialog'
	import {
		queryObligeeList,
		saveOrModify
	} from '@/api/ObligeeService'
	export default {
		name: 'Obligee',
		components: {
			AddCompany,
			AddPersonal,
			RealNameDialog
		},
		data() {

			return {
				tabIdx: 'tab1',
				dialogVisible: false,
				pagination: {
					page: 1,
					pageSize: 10,
					total:0
				},
				tableData: [],


				uploadUrl: `${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
				uploadHeaders: {
					'Authorization': this.$store.getters.token,
					'Product-Mode': "READ",
					'Terminal-Code': "IPCHAIN2" + this.$store.getters.terminalCode,
					'Terminal-Type': "WEB",
					'Merchant-Id': "2"
				},
				showChangeSignature: false,
				form: {
					uniqueCode: '',
					supportingMaterial: {
						signature: ''
					},
				}
				
			}
		},
		created() {
			this.fetchData();

			let uniqueCode = this.$route.query.uniqueCode;
			// console.log(uniqueCode);
			if(uniqueCode){ // 新登记页面选择著作权人，没有印章，跳转过来的
				this.showChangeSignature = true;
				this.form.uniqueCode = uniqueCode;
			}
			
		},
		methods: {
			setTabIdx(tabIdx) {
				this.tabIdx = tabIdx
			},
			clickTab(tabIdx) {
				if(tabIdx != 'tab1' && this.pagination.total <= 0){
					this.dialogVisible = true;
					return;
				}
				this.tabIdx = tabIdx
			},
			 // 日期格式化
			dateFormatter (row, column) {
				if(!row.expireDate){
					return this.dayjs(row.startDate*1000).format("YYYY-MM-DD") + `
					长期有效`;
				}else{
					return this.dayjs(row.startDate*1000).format("YYYY-MM-DD") + `
					` + this.dayjs(row.expireDate*1000).format("YYYY-MM-DD")
				}
			},
			onTabelRowClick(row) {
			},
			onDialogClose(){
			  this.dialogVisible=false;
			},
			tabelCellClass({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (column.property == 'certificate') {
					return 'certificateCell'
				}
			},
			fetchData(param) {
				let that = this;
				queryObligeeList(this.pagination.page, this.pagination.pageSize, this.form).then(({
					data
				}) => {
					// console.log("著作权人接口数据",data);
					that.tableData = data.list;
					that.pagination.total = data.total/1;
				});
			},
			sizeChange(pageSize) {
				this.pagination.pageSize = pageSize;
				this.pagination.page = 1;
				this.fetchData()
			},
			currentChange(pageNum) {
				this.pagination.page = pageNum;
				// 更新表格数据
				this.fetchData()
			},

			//  修改印章
			changeSignature(uniqueCode){
				// console.log(uniqueCode);
				this.showChangeSignature = true;
				this.form.uniqueCode = uniqueCode;
			},

			onCloseChangeSignature(){
				this.showChangeSignature = false;
			},
			uploadSuccess(res, file) {
				this.form.supportingMaterial.signature = res.downloadUrl;
			},
			uploadBefore(file) {
				console.log(file);
				// 电子印章只能是png格式
				const isImage = file.type === 'image/png'; 
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isImage) {
					this.$message.error('上传图片只能是 PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return isImage && isLt2M;
			},
			changeSignatureBtn(){
				if(this.form.supportingMaterial.signature == ''){
					this.$message.error('请先上传电子签名/印章!');
					return false;
				}
				saveOrModify(this.form).then(({ data }) => {
					// console.log(data);
					if(data > 0){ // 大于0就修改成功，这个返回是当前记录的ID
						// console.log('修改成功');
						this.$message.success('修改成功!');
						this.showChangeSignature = false;
						this.form.supportingMaterial.signature = '';
						this.fetchData();
					}
				})
			},

		}
	}
</script>

<style lang='scss' scoped>
	@import '../../../assets/css/mine.scss';

	/deep/.cell{
		display: flex;
		align-items: center;
	}
	/deep/.el-image{
		display: flex;
		align-items: center;
		img{width: 50px;height: 50px;margin-right: 8px;}
	}
	/deep/.el-table td.el-table__cell div{
		white-space: pre-line;
	}

	.changeSignatureBox{
		.uploadBusinessLicense {
			.Uploader {
				width: 132px;
				height: 132px;
				background: #F9FAFC;
				border-radius: 4px;
				border: 1px solid #C0CCDA;
				position: relative;
				margin: 0 auto;
			}
			/deep/.el-upload{
				width: 100%;
			}

			.Uploader:hover {
				border-color: #007DFF;
			}
			
			.Uploader img{
				width: auto;
				max-width: 100%;
				height: auto;
				max-height: 100%;
				position: absolute;
				top:50%; 
				left:50%;
				transform: translate(-50%,-50%);
			}

			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				text-align: center;
				line-height: 130px;
			}

			.avatar {
				width: auto;
				max-width: 100%;
				height: auto;
				max-height: 100%;
				display: block;
				position: absolute;
				top:50%; 
				left:50%;
				transform: translate(-50%,-50%);
			}
			
		}

		.blockReminder {
			background: rgba(255, 231, 186, 0.2);
			border: 1px solid #FFD591;
			padding: 16px;
			width: 100%;
			margin-top: 8px;
			line-height: 1.5;
			.description {
				font-size: 12px;
				font-weight: 400;
				color: rgba(0, 18, 22, 0.5);
			}
		}
		button{
			width: 120px;
			display: block;
			margin: 20px auto;
		}
	}
	
</style>
